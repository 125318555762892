<template>
  <div class="container-fluid mt-5 pt-5">
    <BasePageBreadCrumb :pages="pages" title="nova galeria" :menu="menu" />

    <div class="container-fluid qsuite-c-fluid-0">
      <div class="col-12">
        <div class="card card-bg-trans">
          <div class="card-body pt-4 px-0">
            <form
              class="px-2 needs-validation"
              novalidate
              @submit.prevent="save"
            >
              <div class="form-body">
                <div class="row">
                  <div class="col-12 col-md-12 col-lg-12">
                    <div class="form-group">
                      <label>Nome da galeria</label>
                      <input
                        type="text"
                        id="inputSuccess1"
                        class="form-control"
                        v-model="gallery.name"
                        name="name"
                        v-validate="'required'"
                        :class="{ 'is-invalid': errors.has('name') }"
                      />
                    </div>
                  </div>
                  <div class="col-12 pt-3">Imagens</div>
                  <div class="col-12 mt-2">
                    <div class="row">
                      <div
                        class="col-12 col-md-3 pb-2"
                        v-for="(item, index) in gallery.images"
                        :key="index"
                      >
                        <label>Imagem</label>
                        <BaseImageUpload
                          v-model="gallery.images[index].image"
                        />
                        <div class="row px-2">
                          <input
                            type="text"
                            class="form-control col-9 mt-2"
                            v-model="gallery.images[index].subtitle"
                          />
                          <button
                            class="btn col-2 d-flex justify-content-center align-items-center"
                            @click="remove(index)"
                          >
                            <i class="fas fa-times text-danger"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="btn btn-info mt-3 ml-2"
                    @click="addImage"
                  >
                    Adicionar imagem
                  </button>
                </div>
              </div>
              <div class="form-actions">
                <div class="text-right">
                  <button type="reset" class="btn btn-dark">Cancelar</button>
                  <button type="submit" class="btn btn-info ml-2">
                    {{ id ? "Salvar" : "Cadastrar" }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import KitConfigService from "@/services/resources/KitConfigService";
import VueUploadMultipleImage from "vue-upload-multiple-image";

const service = KitConfigService.build();

export default {
  components: {
    VueUploadMultipleImage,
  },
  data() {
    return {
      gallery: {
        name: null,
        images: [
          {
            subtitle: null,
            image: [],
          },
        ],
        type: "GALLERY",
      },
      search: "",
      id: null,
      pages: [
        {
          name: "dashboard",
          to: "Dashboard",
        },
      ],
      menu: [
        {
          name: "Dashboard",
          to: "Dashboard",
        },
      ],
    };
  },
  methods: {
    addImage() {
      this.gallery.images.push({
        image: [],
        subtitle: null,
      });
    },
    remove(index) {
      this.$delete(this.gallery.images, index);
    },
    save() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let gallery = Object.assign({}, this.gallery);
          gallery.images = gallery.images.map((elem) => {
            return {
              image: elem.image[0],
              subtitle: elem.subtitle,
            };
          });

          if (this.id) {
            gallery.id = this.id;
            gallery.url = "single";

            service
              .update(gallery)
              .then((resp) => {
                this.$bvToast.toast("Sua galeria foi atualizada com sucesso!", {
                  title: "Galeria atualizada",
                  autoHideDelay: 5000,
                  type: "success",
                });
              })
              .catch((err) => {});
          } else {
            service
              .create(gallery)
              .then((resp) => {
                this.$bvToast.toast("Sua galeria foi criada com sucesso!", {
                  title: "Galeria criada",
                  autoHideDelay: 5000,
                  type: "success",
                });
              })
              .catch((err) => {});
          }
        }
      });
    },
    fetchGallery() {
      if (!this.id) {
        return;
      }

      let data = {
        id: this.id,
      };

      service
        .read(data)
        .then((resp) => {
          this.gallery.name = resp.name;
          this.gallery.images = resp.images.map((elem) => {
            return {
              image: [{ path: elem.path }],
              subtitle: elem.subtitle,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.fetchGallery();
  },
};
</script>
<style scoped lang="scss">
.bg {
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
}
</style>